import React from "react";

const ResponsibleGaming = () => {
  return (
    <>
      <div className="side-head">
        <h3>Responsible Gaming</h3>
      </div>
      <div className="announce-wrap">
        <p>Responsible Gaming</p>
        <p>
          bettbuzz365 is committed to endorsing responsible wagering among its
          customers as well as promoting the awareness of problem gambling and
          improving prevention, intervention and treatment.
        </p>
        <p>
          bettbuzz365&rsquo;s Responsible Gambling Policy sets out its commitment to
          minimizing the negative effects of problem gambling and to promoting
          responsible gambling practices.
        </p>
        <p>
          bettbuzz365 supports the generation of online gamblers offering them a
          wide range of games and entertainment. We also take responsibility for
          our product line-up.
        </p>
        <p>
          The aim of bettbuzz365 is to provide the world&rsquo;s safest and most
          innovative gaming platform for adults. The offered clear and safe
          products allow each user to play within his financial means and to
          receive the highest quality service. Integrity, fairness and
          reliability are the guiding principles of Skyexchange&rsquo;s work. It
          is therefore clear that bettbuzz365 should do its best to avoid and reduce
          the problems, which can arise from participation in gambling,
          particularly in cases of immoderate playing. At the same time it is
          important to respect the rights of those who take part in games of
          chance to a reasonable extent as means of entertainment.
        </p>
        <p>
          Responsible Gaming at Skyexchange is based on three fundamental
          principles: Security of the player, Security of the game and
          Protection against gaming addiction. Together with research
          institutes, associations and therapy institutions, we work on creation
          of a responsible, secure and reliable framework for online gaming.
        </p>
        <p>Player security</p>
        <p>
          We take responsibility for the security of our players. Protection of
          the players is based on forbidding the attendance of the minors from
          participation in games and the protection of privacy, which involves
          responsible processing of personal data and payments. Fairness and the
          random nature of the products offered are monitored closely by
          independent organizations. Marketing communication is also geared
          towards player protection: we promise only what players can receive in
          our transparent line.
        </p>
        <p>
          Protection against gaming addiction: research &ndash; prevention
          &ndash; intervention
        </p>
        <p>
          The majority of users who make sports bets, casino bets and other
          gaming offers play in moderation for entertainment. Certain habits and
          behavior patterns (such as shopping, playing sports, eating or
          consumption of alcohol) which are considered to be normal and not
          causing any concern can develop into addiction for some people and
          cause problems. In the same way, bets on sports and gambling can lead
          to problems for a small group of customers.
        </p>
        <p>
          Clients with gaming addiction are prohibited from further
          participation in the gaming line-up. Subsequently the customers are
          provided with contacts of organizations where they can receive
          professional advice and support.
        </p>
        <p>Self-responsibility is the most sustainable form of prevention</p>
        <p>
          The basic principle promoted by Skyexchange is that the final decision
          and responsibility on whether to play or not, and how much money can
          be spent on the game should be assumed by the customer himself.
        </p>
        <p>
          Self-responsibility of the customer is therefore the most effective
          form of protection from addiction. Skyexchange sees its responsibility
          in assisting the customers by providing transparent products, full
          information and keeping a clear line of conduct.
        </p>
        <p>Protection of minors</p>
        <p>
          bettbuzz365 does not allow minors (persons under the age of 18) to
          participate in games and make bets. That&rsquo;s why the confirmation
          of having reached the age of majority and the confirmation of date of
          birth are mandatory requirements during registration. Skyexchange
          considers the issue of minors taking part in games and betting very
          seriously. In order to offer the best possible protection of minors,
          we also rely on the support of parents and caregivers. Please keep
          your data for account access in a safe place (user ID and password).
        </p>
        <p>
          Furthermore, we would recommend that you install filter software. This
          software will allow you to restrict the access to Internet resources
          inappropriate for children and teenagers. These include:
          <br />
          www.netnanny.com
          <br />
          www.cybersitter.com
        </p>
        <p>Responsibility towards problems</p>
        <p>
          bettbuzz365 offers a variety of games and bets, which are forms of
          entertainment for the majority of customers. At the same time the
          company takes responsibility for its customers by providing support
          and tools for maintenance of a secure and entertaining environment
          taking into account the associated risks.
        </p>
        <p>
          The clients who have difficulty in assessing risks, recognizing their
          own limits or those who suffer from gambling addiction are not able to
          enjoy our product line-up responsibly and perceive it as a form of
          entertainment. Skyexchange takes responsibility for such users by
          blocking their access to its products for their own protection.
        </p>
        <p>Get informed with the main issues!</p>
        <p>
          Most people play for pleasure. Moderate participation in games within
          their financial capacity is fully acceptable. However, for a small
          percentage of people gambling is not a form of entertainment, it is a
          challenge that must be considered seriously.
        </p>
        <p>What is the problematic game behavior?</p>
        <p>
          A problematic game behavior is considered to be such behavior, which
          interferes mode of life, work, financial position or health of a
          person or his family. Long participation in games is counter
          indicative to such person as it can lead to negative consequences.
        </p>
        <p>
          In 1980 the pathological game dependence has been officially
          recognized and enlisted in the list of psychological diseases of
          international classification system DSM-IV and ICD-10. It is defined
          as long, repeating and frequently amplifying inclination for game,
          despite of existing negative personal and social circumstances, such
          as a debt, rupture of family relations and delay of professional
          growth.
        </p>
        <p>
          In what cases behavior of a person should be considered as dependence?
        </p>
        <p>
          It is necessary to underline that the diagnoses of game dependence can
          be qualified only by experts. The material presented on this web-page
          will help you to estimate and define your own game behaviour.
        </p>
        <p>
          The special hazard of addictions that are not associated with any
          substance is that it is very difficult to define the line between
          pleasure and addiction. Nevertheless, there are some diagnostic
          signals that may point out the existing problems. In the presence of
          at least five of the following symptoms, the likelihood of the severe
          dependence is high:
        </p>
        <p>
          The player is deeply involved in gambling, all his thoughts are only
          about the game.
          <br />
          Bet sum increases in course of time.
          <br />
          Attempts to quit or control his participation in the games appear to
          be unsuccessful.
          <br />
          When limiting his participation in gambling, a person experiences
          irritation and disappointment.
          <br />
          The game is a way to escape from problems or discomfort.
          <br />
          The player tries to win back the lost amount,
          <br />
          Lies about his playing behavior,
          <br />
          Commits illegal acts,
          <br />
          Spoils or breaks the relationship with family and colleagues,
          <br />
          Borrows to participate in the games.
          <br />
          Rules for responsible games
        </p>
        <p>
          Following the rules placed below, you can enjoy the game without
          anxiety:
        </p>
        <p>
          Start playing only when you are calm and concentrated.
          <br />
          Take regular breaks.
          <br />
          Define for yourself beforehand the monthly amount you can spend on the
          game.
          <br />
          Once setting a maximum limit, do not further increase it.
          <br />
          Before you start playing, define the maximum amount of winning, after
          reaching of which you should stop playing.
          <br />
          Define the amount you can afford to lose beforehand.
          <br />
          Do not start playing under alcohol or drug influence.
          <br />
          Do not start playing in a depressed state.
          <br />
          Exclusion from games
        </p>
        <p>
          Should the player feel they have a problem or wish to take an enforced
          break they can do so by using the following online tool to prevent
          access to gambling sites:www.gamblock.com
        </p>
        <p>
          At any time should the player feel that they have a problem they can
          contact us to have their account permanently closed.
        </p>
        <p>
          In addition to this, should we feel that at any time the player has a
          problem then we reserve the right to suspend the account until the
          player has proven their level of activity is not a problem or
          permanently close the account.
        </p>
        <p>Seeking help</p>
        <p>
          Organizations are available to support players with gaming addictions
          and other disorders related to games and we recommend any player with
          an issue contacts a self-help organization for additional assistance.
          The following website can offer help and guidance to those in need:
          www.gamblingtherapy.org
        </p>
      </div>
    </>
  );
};

export default ResponsibleGaming;
